/** @format */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid2, Typography, Button, Divider, useMediaQuery, MenuItem, Box } from '@mui/material';

import keeboHealthByTricogImage from '../assets/images/keebohealth-by-tricog.png';
import tricogLogoWhite from '../assets/svgs/tricog-logo-white.svg';

interface FooterPropsType {
  size: number;
}

const Footer: React.FunctionComponent<FooterPropsType> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Grid2
      /* AOS */
      // data-aos='fade-up'
      // data-aos-duration='1000'
      /* AOS */
      gap={5}
      pt={11}
      container
      direction={'column'}
      sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#111827' }}>
      {/* TITLE */}
      <Grid2 textAlign={(isMobile && 'center') || 'left'} maxWidth={'1440px'}>
        <Typography variant='font54Bold' sx={{ color: '#fff' }}>
          Get in touch with us.
        </Typography>
      </Grid2>

      {/* BUTTON */}
      <Grid2 container direction={'row'} maxWidth={'1440px'}>
        <Button
          variant='contained'
          size='large'
          sx={{
            borderRadius: 50,
            '&:hover': {
              backgroundColor: 'black',
              color: '#fff',
              border: 'none',
            },
            backgroundColor: '#007aff',
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
          onClick={() => navigate('contact')}>
          <Typography textTransform={'none'} variant='font18SemiBold' sx={{ color: '#fff' }}>
            Contact Us
          </Typography>
        </Button>
      </Grid2>

      <Grid2 container mt={0} maxWidth={'1440px'}></Grid2>

      {/* DIVIDER */}
      <Grid2 maxWidth={'1440px'}>
        <Grid2 width={'100vw'}></Grid2>
        <Divider sx={{ bgcolor: '#384855' }} orientation='horizontal' variant='fullWidth' flexItem />
        <Grid2 width={'100vw'}></Grid2>
      </Grid2>

      {/* LOGO & RESOURCES */}
      <Grid2 container direction={(!isMobile && 'row') || 'column'} /* gap={25} */ columnGap={5} rowGap={10} pt={5} pb={5} size={props.size} maxWidth={'1440px'}>
        {/* LOGO & COMPANY NAME */}
        <Grid2 container direction={'row'} gap={2} alignItems={'flex-start'} height={10} marginRight={11}>
          {/* LOGO */}
          <Grid2 alignSelf={'center'}>
            <img src={keeboHealthByTricogImage} alt='keebo-health-logo' style={{ width: isMobile ? '150px' : '185px', height: 'auto' }} />
          </Grid2>

          {/* DIVIDER */}
          {/* <Grid2 alignSelf={'stretch'}>
            <Divider sx={{ bgcolor: '#384855' }} orientation='vertical' variant='fullWidth' />
          </Grid2> */}

          {/* COMPANY NAME */}
          {/* <Grid2 alignSelf={'center'}>
            <Typography variant='font14Regular' sx={{ color: '#fafafb' }}>
              by Tricog
            </Typography>
          </Grid2> */}
        </Grid2>

        {/* DIVIDER */}
        {!isMobile && (
          <Grid2>
            <Divider sx={{ bgcolor: '#384855' }} orientation='vertical' variant='fullWidth' />
          </Grid2>
        )}

        {/* RESOURCES */}
        <Grid2 container direction={'column'} gap={0} alignItems={'flex-start'}>
          <Typography variant='font20SemiBold' sx={{ color: '#fff' }} mb={2.2}>
            Resources
          </Typography>

          <MenuItem onClick={() => window.open('https://www.tricog.com/about-us/')} sx={{ padding: 0 }}>
            <Typography variant='font18Regular' sx={{ color: '#fff' }}>
              About Tricog
            </Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/features')} sx={{ padding: 0 }}>
            <Typography variant='font18Regular' sx={{ color: '#fff' }}>
              Features
            </Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/plans')} sx={{ padding: 0 }}>
            <Typography variant='font18Regular' sx={{ color: '#fff' }}>
              Care Plans
            </Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/contact')} sx={{ padding: 0 }}>
            <Typography variant='font18Regular' sx={{ color: '#fff' }}>
              Contact
            </Typography>
          </MenuItem>
        </Grid2>
      </Grid2>

      {/* DIVIDER */}
      <Grid2 maxWidth={'1440px'}>
        <Grid2 width={'100vw'}></Grid2>
        <Divider sx={{ bgcolor: '#384855' }} orientation='horizontal' variant='fullWidth' flexItem />
        <Grid2 width={'100vw'}></Grid2>
      </Grid2>

      {/* COPYRIGHT */}
      <Grid2 container direction={'row'} justifyContent={(isMobileSmall && 'flex-start') || 'flex-start'} alignItems={'center'} pb={5} size={10} maxWidth={'1440px'}>
        <Box
          component='img'
          src={tricogLogoWhite}
          alt='Illustration'
          sx={{
            marginLeft: -1.2,
            width: 120,
            cursor: 'pointer',
          }}
          onClick={() => window.open('https://www.tricog.com/')}
        />
        <Typography variant='font16Regular' sx={{ color: '#eff1f3' }}>
          © Copyright 2024, All Rights Reserved by Tricog Health
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default Footer;
