/** @format */

import * as React from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import CardWithBGImage from '../components/CardWithBGImage.tsx';

export default function Plans() {
  const pricingCards = [
    {
      title: 'Basic',
      subTitle: 'Package Includes : ',
      includedFeatures: ['Essential monitoring devices (blood pressure monitor, digital scale)', 'Basic vital monitoring'],
    },
    {
      title: 'Lite',
      subTitle: 'Package Includes : ',
      includedFeatures: ['Essential monitoring devices (blood pressure monitor, digital scale)', 'Patients with their own compatible smart watch ', 'Expanded monitoring functionalities'],
    },
    {
      title: 'Complete',
      subTitle: 'Package Includes : ',
      includedFeatures: ['KeeboHealth verified wearables and devices (blood pressure monitor, digital scale)', 'Comprehensive health monitoring (heart rate, activity tracking, sleep analysis etc.)'],
    },
  ];

  const _renderPlanCards = (item, index) => {
    return (
      <Grid2 key={index}>
        <Grid2
          sx={{
            background: 'rgb(17, 24, 39)',
            color: 'white',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'left',

            maxWidth: '400px',
            maxHeight: '100px',

            backgroundColor: index % 2 !== 0 ? '#3b82f6' : '#141414',
          }}>
          <Typography width={'100%'} textAlign={'left'} p={4} variant='font24Bold' sx={{ color: '#fff' }}>
            {item.title}
          </Typography>
        </Grid2>
        <Grid2
          // container
          sx={{
            border: '0.5px solid grey',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',

            padding: '25px 25px',

            maxWidth: '400px',
            minHeight: '270px',
            maxHeight: '270px',
          }}>
          <Box component={'div'}>
            <Typography
              variant='font18SemiBold'
              sx={{
                color: '#141414',
              }}>
              {item.subTitle}
            </Typography>
            {item.includedFeatures.map((feature, index) => {
              return (
                <Grid2 container key={index} justifyContent={'flex-start'} size={12} mt={(index === 0 && 3) || 0}>
                  {/*  */}
                  <Grid2 size={1}>
                    <Typography component={'span'}>
                      <CheckCircleOutlineIcon fontSize='small' />
                    </Typography>
                  </Grid2>

                  {/*  */}

                  <Grid2 size={11}>
                    <Typography component={'span'} display='flex' justifyContent={'flex-start'} pb={1.8} variant='font18Regular' sx={{ color: '#384855' }}>
                      {feature}
                    </Typography>
                  </Grid2>
                </Grid2>
              );
            })}
          </Box>
        </Grid2>
      </Grid2>
    );
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */
        container
        gap={5}
        justifyContent={'center'}
        margin={'auto'}
        mt={10}
        mb={10}
        size={{ xs: 12 }}>
        {pricingCards.map((ele, index) => _renderPlanCards(ele, index))}
      </Grid2>

      <Grid2 /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */ container
        justifyContent={'center'}
        margin={'auto'}
        size={{ xs: 12 }}>
        <CardWithBGImage />
      </Grid2>
    </>
  );
}
