/** @format */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, Grid2, Typography, useMediaQuery } from '@mui/material';

import whyCard from '../assets/images/whyCard.webp';

import userCard from '../assets/images/userCard.png';
import Wearables from '../assets/images/Wearables.png';
import WeighingScale from '../assets/images/weighing-machine.png';
import BloodPressureMonitor from '../assets/images/omron-devices.png';

import cardGraph from '../assets/images/graph.jpg';
import cardUser from '../assets/images/cardUser.jpg';
import typeCard from '../assets/images/typeCard.jpg';
import cardRead from '../assets/images/cardRead.jpg';
import handeshakeCard from '../assets/images/handstakeCard.jpg';
import graphImageCard from '../assets/images/graphImageCard.jpg';

import userSection from '../assets/images/user-section.avif';

import Card from '../common/Card.tsx';
import FAQAccordion from '../common/FAQAccordion.tsx';

import '../css/animations.scss';

import './../css/fonts.scss';
import './../css/animations.scss';

const KeeboHealth = () => {
  const theme = useTheme();
  const isMobileSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const allCardsDataOne = [
    {
      img: cardGraph,
      frontText: 'Enhanced Patient Monitoring',
      backText: 'Track vitals like blood pressure and SpO2 in real time. Get alerts for abnormalities, enabling timely interventions and effective chronic condition management.',
    },

    {
      img: cardUser,
      frontText: 'Data-Driven Decisions',
      backText: 'Use health data trends to personalise treatments, predict risks, and improve diagnostic accuracy for better patient outcomes.',
    },

    {
      img: typeCard,
      frontText: 'Improved Patient Engagement',
      backText: 'Communicate via secure messaging and video calls, fostering trust and boosting care plan adherence.',
    },
    {
      img: whyCard,
      frontText: 'Efficient Workflow Management',
      backText: 'Simplify tasks with an all-in-one platform, reducing admin work and improving focus on patient care.',
    },
  ];

  const allCardsDataTwo = [
    {
      img: cardRead,
      frontText: 'Proactive Health Management',
      backText: 'The app’s continuous monitoring features and real-time alerts ensure that patients’ health conditions are under constant observation.',
    },

    {
      img: graphImageCard,
      frontText: 'Personalised Care',
      backText: 'Benefit from tailored treatment plans designed to meet individual health needs and improve adherence.',
    },

    {
      img: handeshakeCard,
      frontText: 'Early Detection of Health Issues',
      backText: 'Smart alerts notify Doctors of potential risks early, avoiding hospitalisations and enabling timely care.',
    },
    {
      img: userCard,
      frontText: 'Enhanced Family Involvement',
      backText: 'Caregivers can track progress and assist, creating a supportive and collaborative care environment.',
    },
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/*  */}
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */
        margin={'auto'}
        size={{ xs: 12 }}
        container
        sx={{
          gap: { xs: 10 },
          alignItems: 'center',
          marginTop: { xs: 10 },
          justifyContent: 'center',
          textAlign: { xs: 'center', sm: 'left' },
        }}>
        {/* Image Section */}
        <Grid2
          container
          size={{ xs: 11, md: 5 }}
          sx={{
            width: '100%',
          }}>
          <img
            src={userSection}
            alt='userSection'
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '630px',
              maxHeight: '530px',
              borderRadius: '20px',
              objectFit: 'cover',
            }}
          />
        </Grid2>

        {/* Text Content Section */}
        <Grid2
          container
          size={{ xs: 11, md: 5 }}
          direction='column'
          sx={{
            textAlign: { xs: 'left', sm: 'left' },
            gap: { xs: 2 },
          }}>
          <Grid2>
            <Typography variant='font18Medium' sx={{ color: '#3b82f6' }}>
              About KeeboHealth
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant='font56SemiBold'>Building a healthier tomorrow with care</Typography>
          </Grid2>
          <Grid2>
            <Typography
              variant='font20Regular'
              sx={{
                color: '#384855',
              }}>
              KeeboHealth is a comprehensive software platform designed to assist healthcare providers in managing heart failure patients' medical therapy needs. Our platform includes a web portal and
              a mobile application that work together to improve patient care through personalised and data-driven approaches. By integrating physiological monitoring, medication management, and
              secure communication channels, KeeboHealth empowers Doctors and enhances Patients' lives.
            </Typography>
          </Grid2>
          <Grid2>
            <Button
              variant='outlined'
              sx={{
                padding: '15px 25px',
                borderRadius: '50px',
                ':hover': {
                  color: '#fff',
                  border: 'none',
                  backgroundColor: 'rgb(59, 130, 246)',
                },
                color: '#384855',
              }}
              onClick={() => navigate('/features')}>
              <Typography textTransform='none' variant='font18SemiBold'>
                See how it works
              </Typography>
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      {/*  */}
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        margin='auto'
        direction='column'
        alignItems='center'
        justifyContent='center'
        mt={15}
        mb={15}
        size={{ xs: 12, md: 10 }}>
        <Grid2 size={{ xs: 11, md: 10 }} textAlign={'center'}>
          <Typography variant='font48SemiBold'>Why KeeboHealth?</Typography>
        </Grid2>
        <Grid2 size={{ xs: 11, md: 10 }} textAlign={'center'}>
          <Typography
            variant='font20Regular'
            sx={{
              color: '#384855',
            }}>
            Discover the advantages of using KeeboHealth for both Patients and Doctors
          </Typography>
        </Grid2>
      </Grid2>
      {/*  */}
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        margin={'auto'}
        textAlign='left'
        size={{ xs: 12 }}>
        <Grid2
          container
          margin='auto'
          mb={10}
          p={(isMobileSizeSm && '16px') || '30px'}
          alignItems='center'
          justifyContent='space-between'
          sx={{
            background: '#FAFAFB',
            borderRadius: '20px',
          }}
          size={{ xs: 11 }}>
          <Grid2 container direction='column' size={{ md: 6 }}>
            <Typography
              variant='font42SemiBold'
              sx={{
                color: '#111827',
              }}>
              For Doctors
            </Typography>
            <Typography
              variant='font18Regular'
              sx={{
                color: '#111827',
              }}>
              Our platform eases the already busy life of Doctors, helping them manage their patients efficiently.
            </Typography>
          </Grid2>
          {!isMobileSizeMd && (
            <Grid2 container direction='column' gap={3} size={3} width={'auto'}>
              <svg width='260' height='260' viewBox='0 0 260 260' fill='none' id='svg-1269799071_1914'>
                <mask id='svg-1269799071_1914_mask0_1186_38047' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='0' width='260' height='260'>
                  <rect width='260' height='260' rx='24' fill='#D9D9D9' />
                </mask>
                <g mask='url(#svg-1269799071_1914_mask0_1186_38047)'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M132 -44.5644C132 -45.3573 132.643 -46 133.436 -46H185.564C186.357 -46 187 -45.3573 187 -44.5644V21.6433C187 22.9223 188.546 23.5628 189.451 22.6584L236.267 -24.1576C236.828 -24.7183 237.737 -24.7183 238.298 -24.1576L275.158 12.7031C275.718 13.2637 275.718 14.1727 275.158 14.7333L228.341 61.5493C227.437 62.4537 228.077 64 229.356 64H295.564C296.357 64 297 64.6427 297 65.4356V117.564C297 118.357 296.357 119 295.564 119H229.356C228.077 119 227.437 120.546 228.341 121.451L275.158 168.267C275.718 168.828 275.718 169.737 275.158 170.298L238.298 207.158C237.737 207.718 236.828 207.718 236.267 207.158L189.451 160.341C188.546 159.437 187 160.077 187 161.356V227.564C187 228.357 186.357 229 185.564 229H133.436C132.643 229 132 228.357 132 227.564V161.356C132 160.077 130.454 159.437 129.549 160.341L82.7334 207.158C82.1728 207.718 81.2638 207.718 80.7032 207.158L43.8425 170.296C43.2819 169.735 43.2819 168.827 43.8425 168.266L90.6583 121.451C91.5627 120.546 90.9222 119 89.6432 119H23.4356C22.6427 119 22 118.357 22 117.564V65.4356C22 64.6427 22.6427 64 23.4356 64H89.6434C90.9224 64 91.5629 62.4537 90.6585 61.5493L43.8425 14.7333C43.2819 14.1727 43.2819 13.2637 43.8425 12.7031L80.7032 -24.1575C81.2638 -24.7181 82.1728 -24.7181 82.7334 -24.1575L129.549 22.6584C130.454 23.5628 132 22.9222 132 21.6433V-44.5644ZM159.495 130.788C181.192 130.788 198.78 113.199 198.78 91.5025C198.78 69.8056 181.192 52.2168 159.495 52.2168C137.798 52.2168 120.209 69.8056 120.209 91.5025C120.209 113.199 137.798 130.788 159.495 130.788Z'
                    fill='#EFF1F3'
                  />
                </g>
              </svg>
            </Grid2>
          )}
        </Grid2>

        <Grid2 container columnSpacing={5} justifyContent={'center'} margin={'auto'}>
          <Grid2
            /* AOS */
            data-aos='fade-up'
            // data-aos-duration='1000'
            /* AOS */

            container
            margin='auto'
            spacing={(isMobileSizeSm && 0.1) || 5}
            justifyContent={'center'}>
            {allCardsDataOne.map((item: any, i: number) => {
              if (i === 0 || i === 1) {
                return (
                  <Grid2
                    key={i}
                    /* AOS */
                    // data-aos='zoom-in'
                    // data-aos-delay={`${i * 150}`}
                    /* AOS */
                    sx={{
                      width: '270px',
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}>
                    <Card cardItem={item} />
                  </Grid2>
                );
              }
            })}
          </Grid2>

          {/*  */}

          <Grid2
            /* AOS */
            data-aos='fade-up'
            // data-aos-duration='1000'
            /* AOS */

            container
            margin='auto'
            spacing={(isMobileSizeSm && 0.1) || 5}
            justifyContent={'center'}>
            {allCardsDataOne.map((item: any, i: number) => {
              if (i === 2 || i === 3) {
                return (
                  <Grid2
                    key={i}
                    /* AOS */
                    // data-aos='zoom-in'
                    // data-aos-delay={`${i * 150}`}
                    /* AOS */
                    sx={{
                      width: '270px',
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}>
                    <Card cardItem={item} />
                  </Grid2>
                );
              }
            })}
          </Grid2>
        </Grid2>
      </Grid2>

      {/*  */}
      <Grid2
        container
        // height='25vh'
        textAlign='right'
        /* AOS */
        data-aos='fade-up'
        // data-aos-easing='ease-in-out'
        // data-aos-duration='1000'
        /* AOS */

        mt={10}
        mb={15}
        size={{ xs: 12 }}>
        <Grid2
          container
          // width='70%'
          p={(isMobileSizeSm && '16px') || '30px'}
          margin='auto'
          mb={10}
          alignItems='center'
          justifyContent='space-between'
          sx={{
            background: '#FAFAFB',
            borderRadius: '20px',
          }}
          /* AOS */
          // data-aos='fade-right'
          // data-aos-easing='ease-in-out'
          // data-aos-duration='1200'
          /* AOS */

          size={{ xs: 11 }}>
          {!isMobileSizeMd && (
            <Grid2
              /* AOS */
              // data-aos='zoom-in'
              // data-aos-delay='200'
              /* AOS */

              container
              direction='column'
              gap={3}
              size={3}>
              <svg
                fill='none'
                width='260'
                height='260'
                viewBox='0 0 260 260'
                id='svg-1269799071_1914'
                style={{
                  transform: 'scaleX(-1) translateX(0px)',
                }}>
                <mask id='svg-1269799071_1914_mask0_1186_38047' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='0' width='260' height='260'>
                  <rect width='260' height='260' rx='24' fill='#D9D9D9' />
                </mask>
                <g mask='url(#svg-1269799071_1914_mask0_1186_38047)'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M132 -44.5644C132 -45.3573 132.643 -46 133.436 -46H185.564C186.357 -46 187 -45.3573 187 -44.5644V21.6433C187 22.9223 188.546 23.5628 189.451 22.6584L236.267 -24.1576C236.828 -24.7183 237.737 -24.7183 238.298 -24.1576L275.158 12.7031C275.718 13.2637 275.718 14.1727 275.158 14.7333L228.341 61.5493C227.437 62.4537 228.077 64 229.356 64H295.564C296.357 64 297 64.6427 297 65.4356V117.564C297 118.357 296.357 119 295.564 119H229.356C228.077 119 227.437 120.546 228.341 121.451L275.158 168.267C275.718 168.828 275.718 169.737 275.158 170.298L238.298 207.158C237.737 207.718 236.828 207.718 236.267 207.158L189.451 160.341C188.546 159.437 187 160.077 187 161.356V227.564C187 228.357 186.357 229 185.564 229H133.436C132.643 229 132 228.357 132 227.564V161.356C132 160.077 130.454 159.437 129.549 160.341L82.7334 207.158C82.1728 207.718 81.2638 207.718 80.7032 207.158L43.8425 170.296C43.2819 169.735 43.2819 168.827 43.8425 168.266L90.6583 121.451C91.5627 120.546 90.9222 119 89.6432 119H23.4356C22.6427 119 22 118.357 22 117.564V65.4356C22 64.6427 22.6427 64 23.4356 64H89.6434C90.9224 64 91.5629 62.4537 90.6585 61.5493L43.8425 14.7333C43.2819 14.1727 43.2819 13.2637 43.8425 12.7031L80.7032 -24.1575C81.2638 -24.7181 82.1728 -24.7181 82.7334 -24.1575L129.549 22.6584C130.454 23.5628 132 22.9222 132 21.6433V-44.5644ZM159.495 130.788C181.192 130.788 198.78 113.199 198.78 91.5025C198.78 69.8056 181.192 52.2168 159.495 52.2168C137.798 52.2168 120.209 69.8056 120.209 91.5025C120.209 113.199 137.798 130.788 159.495 130.788Z'
                    fill='#EFF1F3'
                  />
                </g>
              </svg>
            </Grid2>
          )}

          <Grid2
            container
            size={{ md: 6 }}
            direction='column'
            /* AOS */
            // data-aos='fade-left'
            // data-aos-delay='300'
            /* AOS */

            // justifyContent={'flex-end'}
          >
            <Typography
              variant='font42SemiBold'
              sx={{
                color: '#111827',
              }}>
              For Patients
            </Typography>
            <Typography
              variant='font18Regular'
              sx={{
                color: '#111827',
              }}>
              Our platform expands access to healthcare services, even for patients in underserved or remote areas where healthcare facilities may be scarce.
            </Typography>
          </Grid2>
        </Grid2>

        <Grid2 container columnSpacing={5} margin={'auto'}>
          <Grid2
            container
            spacing={(isMobileSizeSm && 0.1) || 5}
            margin='auto'
            /* AOS */
            data-aos='fade-up'
            // data-aos-easing='ease-in-out'
            // data-aos-duration='1000'
            /* AOS */

            // size={{ xs: 12 }}
            justifyContent={'center'}>
            {allCardsDataTwo.map((item: any, i: number) => {
              if (i === 0 || i === 1) {
                return (
                  <Grid2
                    key={i}
                    /* AOS */
                    // data-aos='zoom-in'
                    // data-aos-delay={`${i * 200}`}
                    /* AOS */

                    sx={{
                      width: '270px',
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}>
                    <Card cardItem={item} />
                  </Grid2>
                );
              }
            })}
          </Grid2>

          <Grid2
            container
            spacing={(isMobileSizeSm && 0.1) || 5}
            margin='auto'
            /* AOS */
            data-aos='fade-up'
            // data-aos-easing='ease-in-out'
            // data-aos-duration='1000'
            /* AOS */

            // size={{ xs: 12 }}
            justifyContent={'center'}>
            {allCardsDataTwo.map((item: any, i: number) => {
              if (i === 2 || i === 3) {
                return (
                  <Grid2
                    key={i}
                    /* AOS */
                    // data-aos='zoom-in'
                    // data-aos-delay={`${i * 200}`}
                    /* AOS */

                    sx={{
                      width: '270px',
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}>
                    <Card cardItem={item} />
                  </Grid2>
                );
              }
            })}
          </Grid2>
        </Grid2>
      </Grid2>

      {/*  */}

      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        direction='column'
        mt={10}
        sx={{ justifyContent: 'center', alignItems: 'center', gap: '20px', textAlign: 'center' }}
        size={{ xs: 12 }}
        margin={'auto'}>
        <Grid2 size={{ md: 4 }}>
          <Typography
            variant='font32SemiBold'
            sx={{
              color: '#111827',
            }}>
            Product offering
          </Typography>
        </Grid2>
        <Grid2 size={{ md: 7 }}>
          <Typography
            variant='font20Regular'
            sx={{
              color: '#384855',
            }}>
            At KeeboHealth, besides the digital ecosystem, we offer a range of products to help patients manage their heart failure condition effectively.
          </Typography>
        </Grid2>
      </Grid2>

      {/*  */}

      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        mt={15}
        mb={10}
        spacing={5}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        size={{ xs: 12 }}>
        {[
          { img: BloodPressureMonitor, title: 'Blood Pressure Monitor', maxWidth: 280 },
          { img: WeighingScale, title: 'Weighing Scale', maxWidth: 230 },
          { img: Wearables, title: 'Wearables', maxWidth: 270 },
        ].map((product, index) => (
          <Grid2 key={index} container direction={'column'} gap={2}>
            <Grid2
              sx={{
                textAlign: 'center',
                minHeight: 280,
                width: { xs: 330, md: 350, lg: 400 },
              }}>
              <img src={product.img} alt={product.title} style={{ maxWidth: product.maxWidth }} />
            </Grid2>
            <Grid2 textAlign={'center'}>
              <Typography variant='font20Regular' sx={{ color: '#384855' }}>
                {product.title}
              </Typography>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>

      {/*  */}

      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        direction='column'
        size={12}
        sx={{
          gap: '50px',
          margin: 'auto',
          textAlign: 'left',
          alignItems: 'center',
          paddingBottom: '100px',
        }}>
        <Grid2>
          <Typography variant='font48SemiBold' sx={{ color: '#111827' }}>
            Frequently Asked Questions
          </Typography>
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <FAQAccordion />
        </Grid2>

        <Grid2 mt={5}>
          <Typography textAlign='center' variant='font20Regular' sx={{ color: '#384855' }}>
            Can't find what you're looking for?{' '}
            <u style={{ cursor: 'pointer' }} onClick={() => navigate('/contact')}>
              Contact us
            </u>
          </Typography>
        </Grid2>
      </Grid2>
    </>
  );
};

export default KeeboHealth;
