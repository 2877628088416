/** @format */

import * as React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';

import { Link } from 'react-router-dom';

import header from './../../assets/images/header.jpg';

import keeboHealthLogo from './../../assets/images/keeboHealthLogo.avif';

import './../../css/Navbar.scss';

interface NavbarPropsType {
  children?: React.ReactNode;

  showBGImage?: boolean;

  size?: number;
}

const Navbar: React.FunctionComponent<NavbarPropsType> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navbarRef = React.useRef<HTMLDivElement>(null);

  const [scrolled, setScrolled] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [navbarHeightWidth, setNavbarHeightWidth] = React.useState<{ height: number; width: number; left: number; right: number }>({ height: 0, width: 0, left: 0, right: 0 });

  // Update navbar dimensions
  const updateNavbarDimensions = React.useCallback(() => {
    if (navbarRef?.current) {
      const { offsetHeight, offsetWidth } = navbarRef.current;

      const rect = navbarRef.current?.querySelector('div > div')?.getBoundingClientRect();
      const left = rect?.left ?? 0;
      const right = rect?.right ?? 0;

      setNavbarHeightWidth({ height: offsetHeight, width: offsetWidth, left, right });
    }
  }, []);

  const handleScroll = () => {
    const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollPosition > 10) {
      // Change 100 to the height you want
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchmove', handleScroll);

    // Set up ResizeObserver to track size changes
    const resizeObserver = new ResizeObserver(() => {
      updateNavbarDimensions();
    });

    if (navbarRef.current) {
      resizeObserver.observe(navbarRef.current);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderNavItems = () => {
    return (
      <>
        <Link
          to='/plans'
          className='nav-item'
          style={{
            textDecoration: 'none',
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 400,
            color: 'white',

            transition: 'transform 0.3s ease',
          }}>
          Plans
        </Link>
        <Link to='/features' className='nav-item' style={{ textDecoration: 'none', fontFamily: 'Inter', fontSize: '18px', fontWeight: 400, color: 'white' }}>
          How KeeboHealth works?
        </Link>
        <Link to='/contact' className='nav-item' style={{ textDecoration: 'none', fontFamily: 'Inter', fontSize: '18px', fontWeight: 400, color: 'white' }}>
          Contact Us
        </Link>
      </>
    );
  };

  const navItems = [
    { label: 'Plans', to: '/plans' },
    { label: 'How KeeboHealth Works?', to: '/features' },
    { label: 'Contact Us', to: '/contact' },
  ];

  return (
    <Grid2 container id='main-container' className='main-container'>
      {props.showBGImage && (
        <span className='header-image-background'>
          <img src={header} alt='user-section' className='header-image' />
        </span>
      )}

      <AppBar
        ref={navbarRef}
        position='fixed'
        /*  */
        // data-scroll
        // data-scroll-sticky
        // data-scroll-target='.main-content'
        /*  */
        className='card-0'
        sx={{
          height: 110,
          zIndex: 1000,
          boxShadow: 'none',
          justifyContent: 'center',
          borderBottom: `1px solid ${(scrolled && '#384855') || 'rgb(221, 227, 232)'}`,
          background: scrolled || drawerOpen ? '#111827' : 'transparent',
          transition: 'background 0.3s ease, border-bottom 0.3s ease',
        }}>
        <Toolbar>
          <Grid2 container margin={'auto'} maxWidth={'1440px'} justifyContent={'space-between'} size={props.size} alignItems={'center'}>
            {/* Logo */}
            <Link className='font-inter-normal-16-400' to='/' style={{ textDecoration: 'none', color: 'white' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={keeboHealthLogo} alt='keebo-health-logo' style={{ width: isMobile ? '150px' : '185px', height: 'auto' }} />
              </Box>
            </Link>

            {/* Navigation Links */}
            {!isMobile && <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>{_renderNavItems()}</Box>}

            {/* Mobile Menu Icon */}
            {isMobile && (
              <IconButton edge='start' color='inherit' aria-label='menu' onClick={() => setDrawerOpen((prev) => !prev)}>
                <Menu />
              </IconButton>
            )}
          </Grid2>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor='top'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            paddingLeft: 1.1,
            position: 'absolute',
            backgroundColor: '#111827',
            top: `${navbarRef?.current?.offsetHeight}px`,
          },
        }}>
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#111827',
          }}>
          <List>
            {navItems.map((item, index) => (
              <ListItem key={index} onClick={() => setDrawerOpen(false)}>
                <Link
                  to={item.to}
                  style={{
                    textDecoration: 'none',
                    width: '100%',

                    fontFamily: 'Inter',
                    fontSize: '118px',
                    fontWeight: 400,
                    color: 'white',
                  }}>
                  <ListItemText primary={item.label} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Display Child Component */}
      {props.children && navbarHeightWidth.height && navbarHeightWidth.width && (
        <Box
          maxWidth={'1440px'}
          sx={{
            position: 'absolute',
            top: `${navbarHeightWidth.height + 100}px`,
            left: `${navbarHeightWidth.left}px`,
            // minWidth: `fit-content`,
            width: `${navbarHeightWidth.right - navbarHeightWidth.left}px`,
            textAlign: '-webkit-center',
          }}>
          {props.children}
        </Box>
      )}
    </Grid2>
  );
};

export default Navbar;
