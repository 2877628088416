/** @format */

import React from 'react';
import { Grid2, Typography } from '@mui/material';

import map from './../assets/images/map.avif';

const CardWithBGImage = () => {
  return (
    <Grid2
      container
      p={1}
      sx={{
        margin: 'auto',
        padding: { xs: 0, sm: 5 },
        borderRadius: '20px',
        bgcolor: 'rgb(59, 130, 246)',

        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${map})`,
      }}
      size={{ xs: 12 }}>
      <Grid2
        container
        sx={{
          color: 'white',
          textAlign: 'center',
        }}
        p={5}
        gap={5}>
        <Typography
          variant='font56SemiBold'
          sx={{
            color: '#fff',
          }}>
          Take Control of your Cardiac Health Today: Get Started with Us
        </Typography>
        <Typography
          variant='font20Regular'
          sx={{
            color: '#fff',
          }}>
          Effortless health management: Expert care delivered to your doorstep. Experience convenience and quality in every consultation.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default CardWithBGImage;
