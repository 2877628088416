/** @format */

import AOS from 'aos';
import React from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { Grid2, Typography, useMediaQuery } from '@mui/material';

import { NavRoutes } from './navigation_routes.tsx';

import Footer from './components/Footer.tsx';
import Footer2 from './components/Footer2.tsx';
import Navbar from './components/navigation-bar/Navbar.tsx';

import header from './../src/assets/images/header.jpg';

import './App.scss';
import 'aos/dist/aos.css';

interface AppProps {}

const HomeApp: React.FunctionComponent<AppProps> = () => {
  return useRoutes(NavRoutes);
};

// Extend the Typography interface
declare module '@mui/material/styles' {
  interface TypographyVariants {
    font14Regular: React.CSSProperties;
    font16Regular: React.CSSProperties;
    font18Regular: React.CSSProperties;
    font20Regular: React.CSSProperties;
    font18Medium: React.CSSProperties;
    font20Medium: React.CSSProperties;
    font25Medium: React.CSSProperties;
    font14SemiBold: React.CSSProperties;
    font18SemiBold: React.CSSProperties;
    font20SemiBold: React.CSSProperties;
    font22SemiBold: React.CSSProperties;
    font32SemiBold: React.CSSProperties;
    font35SemiBold: React.CSSProperties;
    font42SemiBold: React.CSSProperties;
    font48SemiBold: React.CSSProperties;
    font54SemiBold: React.CSSProperties;
    font56SemiBold: React.CSSProperties;
    font22Bold: React.CSSProperties;
    font24Bold: React.CSSProperties;
    font36Bold: React.CSSProperties;
    font54Bold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    font14Regular?: React.CSSProperties;
    font16Regular?: React.CSSProperties;
    font18Regular?: React.CSSProperties;
    font20Regular?: React.CSSProperties;
    font18Medium?: React.CSSProperties;
    font20Medium?: React.CSSProperties;
    font25Medium?: React.CSSProperties;
    font14SemiBold?: React.CSSProperties;
    font18SemiBold?: React.CSSProperties;
    font20SemiBold?: React.CSSProperties;
    font22SemiBold?: React.CSSProperties;
    font32SemiBold?: React.CSSProperties;
    font35SemiBold?: React.CSSProperties;
    font42SemiBold?: React.CSSProperties;
    font48SemiBold?: React.CSSProperties;
    font54SemiBold?: React.CSSProperties;
    font56SemiBold?: React.CSSProperties;
    font22Bold?: React.CSSProperties;
    font24Bold?: React.CSSProperties;
    font36Bold?: React.CSSProperties;
    font54Bold?: React.CSSProperties;
  }
}

// Allow variant usage in Typography component
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    font14Regular: true;
    font16Regular: true;
    font18Regular: true;
    font20Regular: true;
    font18Medium: true;
    font20Medium: true;
    font25Medium: true;
    font14SemiBold: true;
    font18SemiBold: true;
    font20SemiBold: true;
    font22SemiBold: true;
    font32SemiBold: true;
    font35SemiBold: true;
    font42SemiBold: true;
    font48SemiBold: true;
    font54SemiBold: true;
    font56SemiBold: true;
    font22Bold: true;
    font24Bold: true;
    font36Bold: true;
    font54Bold: true;
  }
}

function App() {
  const location = useLocation();
  const { hash, pathname, search } = location;

  let theme = createTheme({
    // typography: {
    //   fontFamily: 'Inter',
    // },

    typography: {
      font14Regular: {
        fontFamily: 'Inter',
        // fontSize: '14px',
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      font16Regular: {
        fontFamily: 'Inter',
        // fontSize: '16px',
        fontSize: '1rem',
        fontWeight: 400,
      },
      font18Regular: {
        fontFamily: 'Inter',
        // fontSize: '18px',
        fontSize: '1.125rem',
        fontWeight: 300,
      },
      font20Regular: {
        fontFamily: 'Inter',
        // fontSize: '20px',
        fontSize: '1.25rem',
        fontWeight: 400,
      },
      font18Medium: {
        fontFamily: 'Inter',
        // fontSize: '18px',
        fontSize: '1.125rem',
        fontWeight: 500,
      },
      font20Medium: {
        fontFamily: 'Inter',
        // fontSize: '20px',
        fontSize: '1.25rem',
        fontWeight: 500,
      },
      font25Medium: {
        fontFamily: 'Inter',
        // fontSize: '25px',
        fontSize: '1.563rem',
        fontWeight: 500,
      },
      font14SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '14px',
        fontSize: '0.875rem',
        fontWeight: 600,
      },
      font18SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '18px',
        fontSize: '1.125rem',
        fontWeight: 600,
      },
      font20SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '20px',
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      font22SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '22px',
        fontSize: '1.375rem',
        fontWeight: 600,
      },
      font32SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '32px',
        fontSize: '2rem',
        fontWeight: 600,
      },
      font35SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '35px',
        fontSize: '2.188rem',
        fontWeight: 600,
      },
      font42SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '42px',
        fontSize: '2.625rem',
        fontWeight: 600,
      },
      font48SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '48px',
        fontSize: '3rem',
        fontWeight: 600,
      },
      font54SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '54px',
        fontSize: '3.375rem',
        fontWeight: 600,
      },
      font56SemiBold: {
        fontFamily: 'Inter',
        // fontSize: '56px',
        fontSize: '3.5rem',
        fontWeight: 600,
      },
      font22Bold: {
        fontFamily: 'Inter',
        // fontSize: '22px',
        fontSize: '1.375rem',
        fontWeight: 700,
      },
      font24Bold: {
        fontFamily: 'Inter',
        // fontSize: '24px',
        fontSize: '1.5rem',
        fontWeight: 700,
      },
      font36Bold: {
        fontFamily: 'Inter',
        // fontSize: '36px',
        fontSize: '2.25rem',
        fontWeight: 700,
      },
      font54Bold: {
        fontFamily: 'Inter',
        // fontSize: '50px',
        fontSize: '3.375rem',
        fontWeight: 700,
      },
    },
  });

  theme = responsiveFontSizes(theme);

  const isMobileSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const heightBelow670px = useMediaQuery('(max-height: 670px)');

  const [landingPageChildMarginTop, setLandingPageChildMarginTop] = React.useState(0);

  const smoothScrollRef = React.createRef<HTMLDivElement | any>();
  const bgImageRef = React.useRef<HTMLDivElement>(null);

  const updateLandingPageChildMargin = () => {
    if (bgImageRef.current) {
      const bgImgHeight = bgImageRef && bgImageRef?.current && bgImageRef?.current?.clientHeight;
      setLandingPageChildMarginTop((prev) => (prev !== bgImgHeight ? bgImgHeight : prev));
    }
  };

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateLandingPageChildMargin();
    });

    if (bgImageRef.current) {
      resizeObserver.observe(bgImageRef.current);
    }
  }, []);

  function hasNoSpecifiedTexts(input) {
    const keywords = ['plans', 'features', 'contact'];
    return keywords.some((keyword) => input.includes(keyword));
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 160, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
    AOS.refresh(); // Refresh animations on content change
  }, []);

  const landingPageChildCard = (
    <Grid2
      /* AOS */
      data-aos='fade-up'
      data-aos-offset='-500'
      // data-aos-duration='1000'
      /* AOS */
      container
      gap={(heightBelow670px && 1) || 3}
      direction='column'
      maxWidth={'1440px'}
      justifyContent='space-between'
      sx={{
        textAlign: 'left',
        marginTop: {
          // xxs: `-${landingPageChildMarginTop - 390}px` || '10%',
          // xs: `${landingPageChildMarginTop / 300}px` || '10%',
          xs: -10,
          sm: `${landingPageChildMarginTop / 10}px` || '10%',
          md: `${landingPageChildMarginTop / 5}px` || '10%',
          lg: `${landingPageChildMarginTop / 3}px` || '10%',
        },
        '@media (max-width: 375px)': {
          marginTop: `-${landingPageChildMarginTop - 985}px` || '10%',
        },
        '@media (max-width: 320px)': {
          marginTop: `-${landingPageChildMarginTop - 1200}px` || '10%',
        },
      }}
      size={{ xs: 12 }}>
      {/* Text 1 */}
      <Grid2 size={12} justifyContent={'space-between'} textAlign={(isMobileSizeSm && 'center') || 'left'}>
        {/* <Grid2 size={7}> */}
        <Typography variant='font18Medium' sx={{ color: 'white' }}>
          Your-go-to HF Care Platform
        </Typography>
        {/* </Grid2> */}
        {/* <Grid2 size={{ xs: 4, sm: 4, md: 2, lg: 1.5, xl: 1.3 }}></Grid2> */}
      </Grid2>
      {/* Text 2 & Svg */}
      <Grid2 container direction={(isMobileSizeSm && 'column') || 'row'} size={12} justifyContent={'space-between'} textAlign={(isMobileSizeSm && 'center') || 'left'}>
        {/* Text 2 */}
        <Grid2 container size={{ xs: 12, md: 7 }} gap={(heightBelow670px && 1) || 3}>
          <Grid2>
            <Typography
              component={'div'}
              variant='font54SemiBold'
              sx={{
                color: 'white',
              }}>
              Connected Cardiac Care
            </Typography>
            <Typography component={'div'} variant='font35SemiBold' sx={{ color: '#FF8800' }}>
              Empowering Doctors, Enhancing Patient Lives
            </Typography>
          </Grid2>
          <Grid2>
            <Typography
              variant='font20Medium'
              sx={{
                color: 'white',
              }}
              color='white'>
              Revolutionising heart failure management with innovative remote monitoring and personalised care solutions.
            </Typography>
          </Grid2>
        </Grid2>
        {/* Svg */}
        <Grid2
          container
          // size={{ xs: 4, sm: 4, md: 2, lg: 1.5, xl: 1.3 }}
          // size={{ xs: 12 }}
          mt={(isMobileSizeSm && 2) || 0}
          width={{ xs: 115, sm: 190 }}
          alignSelf={(isMobileSizeSm && 'center') || 'flex-end'}
          sx={{
            visibility: heightBelow670px ? 'hidden' : 'visible',
          }}>
          {/* <svg height='auto' width='100%' viewBox='0 0 190 190' preserveAspectRatio='xMidYMid meet'> */}
            {/* Background Circles */}
            {/* <circle r='90' cx='100' cy='100' fill='#3B82F6' /> */}
            {/* <circle r='55' cx='100' cy='100' stroke='white' strokeWidth='1' fill='transparent' /> */}

            {/* Rotating Text */}
            {/* <g> */}
              {/* <path */}
                {/* id='curve' */}
                {/* d='M 135,100 */}
                      {/* m -100, 0 */}
                      {/* a 55,55 0 1,1 132,0 */}
                      {/* a 55,55 0 1,1 -132,0' */}
                {/* fill='transparent' */}
                {/* stroke='none' */}
              {/* /> */}
              {/* <text className='rotating-text' fill='white' fontSize='22' fontWeight='bold'> */}
                {/* <textPath href='#curve' startOffset='0%' textAnchor='start' style={{ fontFamily: 'Inter', fontSize: '23px', fontWeight: 600, color: 'white' }}> */}
                  {/* KEEBOHEALTH - CARDIAC CARE */}
                {/* </textPath> */}
              {/* </text> */}
            {/* </g> */}

            {/* Nested SVG for Arrow */}
            {/* <svg x='65' y='65' viewBox='0 0 24 48' width='70' height='95'> */}
              {/* <path d='M12 3v36M6 8l6-6 6 6' stroke='white' strokeWidth='1' fill='none' strokeLinecap='round' strokeLinejoin='round' transform='rotate(45, 13, 20)' /> */}
            {/* </svg> */}
          {/* </svg> */}
        </Grid2>
      </Grid2>
    </Grid2>
  );

  const plansPageChildCard = (
    <Grid2
      /* AOS */
      // data-aos='fade-up'
      // data-aos-duration='1000'
      /* AOS */

      container
      sx={{
        color: 'white',
        textAlign: 'left',
        paddingBottom: 20,
        background: 'rgb(17, 24, 39)',
      }}
      size={{ xs: 12 }}>
      <Grid2
        /* AOS */
        // data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        maxWidth={'1440px'}
        size={{ xs: 9.99 }}
        sx={{
          gap: '20px',
          margin: 'auto',
          marginTop: 25,
          flexDirection: 'column',
        }}>
        <Typography
          variant='font48SemiBold'
          sx={{
            color: '#fff',
          }}>
          Discover the perfect plan for you
        </Typography>
        <Typography variant='font20Regular' sx={{ color: '#fff' }}>
          Choose the plan that best fits your needs.
        </Typography>
      </Grid2>
    </Grid2>
  );

  const featuresPageChildCard = (
    <Grid2
      /* AOS */
      //  data-aos='fade-up'
      // data-aos-duration='1000'
      /* AOS */

      container
      sx={{
        color: 'white',
        textAlign: 'left',
        paddingBottom: 20,
        background: 'rgb(17, 24, 39)',
      }}
      size={{ xs: 12 }}>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        maxWidth={'1440px'}
        sx={{
          gap: '20px',
          margin: 'auto',
          marginTop: 25,
          flexDirection: 'column',
        }}
        size={{ xs: 9.99 }}>
        <Grid2>
          <Typography variant='font48SemiBold' sx={{ color: '#fff' }}>
            How KeeboHealth works ?{' '}
          </Typography>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 7 }}>
          <Typography variant='font20Regular' sx={{ color: '#fff' }}>
            KeeboHealth leverages cutting-edge technology to provide continuous monitoring and personalised care for heart failure patients, with few easy steps.
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );

  const contactPageChildCard = (
    <Grid2
      container
      sx={{
        color: 'white',
        textAlign: 'left',
        paddingBottom: 20,
        background: 'rgb(17, 24, 39)',
      }}
      size={{ xs: 12 }}>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        maxWidth={'1440px'}
        sx={{
          margin: 'auto',
          marginTop: 25,
          flexDirection: 'column',
          gap: '20px',
        }}
        size={{ xs: 9.99 }}>
        <Grid2 size={{ xs: 12, md: 7 }}>
          <Typography variant='font48SemiBold' sx={{ color: '#fff' }}>
            Reach out to us for expert care, support & partnership
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Typography variant='font20Regular' sx={{ color: '#fff' }}>
            Whether you have questions, feedback, or want to explore partnership opportunities, we're here to assist.
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid2 id={'App'} container direction={'column'} size={12} margin={'auto'}>
        {/* Background Image */}
        {!hasNoSpecifiedTexts(pathname) && (
          <Grid2 ref={bgImageRef} size={12} margin={'auto'}>
            <span className='header-image-background'>
              <img src={header} alt='user-section' className='header-image' />
            </span>
          </Grid2>
        )}

        {hasNoSpecifiedTexts(pathname) && (
          <Grid2 size={12} margin={'auto'}>
            {pathname === '/plans' && plansPageChildCard}
            {pathname === '/features' && featuresPageChildCard}
            {pathname === '/contact' && contactPageChildCard}
          </Grid2>
        )}

        {/* Sticky Navbar */}
        <Grid2 container size={10} margin={'auto'} data-scroll data-scroll-sticky data-scroll-target='.main-content'>
          <Navbar size={12} children={!hasNoSpecifiedTexts(pathname) && landingPageChildCard} />
        </Grid2>

        {/* Scrollable content */}
        <Grid2 container size={11} margin={'auto'} maxWidth={'1440px'} data-scroll-container ref={smoothScrollRef} className='main-content'>
          <HomeApp />
        </Grid2>

        <Grid2 container size={10} margin={'auto'} maxWidth={'1440px'}>
          {hasNoSpecifiedTexts(pathname) && <Footer2 />}
        </Grid2>
      </Grid2>

      <Grid2 container size={12} margin={'auto'}>
        {!hasNoSpecifiedTexts(pathname) && <Footer size={10} />}
      </Grid2>
    </ThemeProvider>
  );
}

export default App;
